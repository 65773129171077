import { defineEntity, entity, foreign, object, string, timestamps } from '~/prix'
import { multiPolygon, point } from '~/prix/types/geoJson'

const streetEntity = defineEntity({
  key: 'street',
  title: 'Logradouro',
  alternativeTitles: ['Rua', 'Avenida'],
  sources: ['olap', 'oltp'],
  schema: object({
    id: string().label('ID', 'Identificador', 'Código'),
    name: string().label('Nome'),
    cityId: foreign(entity('city').property('id')),
    center: point().label('Ponto central da rua'),
    alternativeNames: string().label('Nomes alternativos'),
    openStreetMapWayCode: string().label('Código ID do Open Street Map'),
    collection: multiPolygon().label('Coleção ways que pertencem a rua.'),
    ...timestamps,
  }),
})

export default streetEntity
