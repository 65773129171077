import { entity, errors, query, string, some, equals, isNull } from '~/prix'
import asGeoJson from '~/prix/functions/asGeoJson'
import { GeoLevel } from '../legalEntityGeoprocessingMapLevels.data'
import concat from '~/prix/functions/concat'

export default function anyLevelQuery(level: GeoLevel, ids: string[], limit: number = 100) {
  switch (level) {
    case 'country':
      return query('country')
        .select({
          id: entity('country').property('id'),
          name: entity('country').property('name'),
          boundary: asGeoJson(entity('country').property('boundary')),
          center: asGeoJson(entity('country').property('center')),
        })
        .where(
          some(...ids.map(id => equals(entity('country').property('id'), string().value(id)))),
          isNull(entity('country').property('deletedAt')),
        )
        .limit(limit)
    case 'macroRegion':
      return query('macroRegion')
        .select({
          id: entity('macroRegion').property('id'),
          name: entity('macroRegion').property('name'),
          boundary: asGeoJson(entity('macroRegion').property('boundary')),
          lowerQualityBoundary: asGeoJson(entity('macroRegion').property('lowerQualityBoundary')),
          center: asGeoJson(entity('macroRegion').property('center')),
        })
        .where(
          some(...ids.map(id => equals(entity('macroRegion').property('id'), string().value(id)))),
          isNull(entity('macroRegion').property('deletedAt')),
        )
        .limit(limit)
    case 'state':
      return query('state')
        .select({
          id: entity('state').property('id'),
          name: entity('state').property('name'),
          boundary: asGeoJson(entity('state').property('boundary')),
          lowerQualityBoundary: asGeoJson(entity('state').property('lowerQualityBoundary')),
          center: asGeoJson(entity('state').property('center')),
        })
        .where(
          some(...ids.map(id => equals(entity('state').property('id'), string().value(id)))),
          isNull(entity('state').property('deletedAt')),
        )
        .limit(limit)
    case 'mesoRegion':
      return query('mesoRegion')
        .select({
          id: entity('mesoRegion').property('id'),
          name: concat([
            entity('mesoRegion').property('name'),
            string().value(' - '),
            entity('state').property('abbreviation'),
          ]),
          boundary: asGeoJson(entity('mesoRegion').property('boundary')),
          lowerQualityBoundary: asGeoJson(entity('mesoRegion').property('lowerQualityBoundary')),
          center: asGeoJson(entity('mesoRegion').property('center')),
        })
        .join({
          current: entity('mesoRegion').property('stateId'),
          target: entity('state').property('id'),
          join: 'inner',
        })
        .where(
          some(...ids.map(id => equals(entity('mesoRegion').property('id'), string().value(id)))),
          isNull(entity('mesoRegion').property('deletedAt')),
        )
        .limit(limit)
    case 'microRegion':
      return query('microRegion')
        .select({
          id: entity('microRegion').property('id'),
          name: concat([
            entity('microRegion').property('name'),
            string().value(' - '),
            entity('state').property('abbreviation'),
          ]),
          boundary: asGeoJson(entity('microRegion').property('boundary')),
          lowerQualityBoundary: asGeoJson(entity('microRegion').property('lowerQualityBoundary')),
          center: asGeoJson(entity('microRegion').property('center')),
        })
        .join({
          current: entity('microRegion').property('mesoRegionId'),
          target: entity('mesoRegion').property('id'),
          join: 'inner',
        })
        .join({
          current: entity('mesoRegion').property('stateId'),
          target: entity('state').property('id'),
          join: 'inner',
        })
        .where(
          some(...ids.map(id => equals(entity('microRegion').property('id'), string().value(id)))),
          isNull(entity('microRegion').property('deletedAt')),
        )
        .limit(limit)
    case 'city':
      return query('city')
        .select({
          id: entity('city').property('id'),
          name: concat([
            entity('city').property('name'),
            string().value(' - '),
            entity('state').property('abbreviation'),
          ]),
          boundary: asGeoJson(entity('city').property('boundary')),
          lowerQualityBoundary: asGeoJson(entity('city').property('lowerQualityBoundary')),
          center: asGeoJson(entity('city').property('center')),
        })
        .join({
          current: entity('city').property('microRegionId'),
          target: entity('microRegion').property('id'),
          join: 'inner',
        })
        .join({
          current: entity('microRegion').property('mesoRegionId'),
          target: entity('mesoRegion').property('id'),
          join: 'inner',
        })
        .join({
          current: entity('mesoRegion').property('stateId'),
          target: entity('state').property('id'),
          join: 'inner',
        })
        .where(
          some(...ids.map(id => equals(entity('city').property('id'), string().value(id)))),
          isNull(entity('mesoRegion').property('deletedAt')),
        )
        .limit(limit)
    case 'neighborhood':
      return query('neighborhood')
        .select({
          id: entity('neighborhood').property('id'),
          name: concat([
            entity('neighborhood').property('name'),
            string().value(' - '),
            entity('city').property('name'),
          ]),
          boundary: asGeoJson(entity('neighborhood').property('boundary')),
          center: asGeoJson(entity('neighborhood').property('center')),
        })
        .join({
          current: entity('neighborhood').property('cityId'),
          target: entity('city').property('id'),
          join: 'inner',
        })
        .where(
          some(...ids.map(id => equals(entity('neighborhood').property('id'), string().value(id)))),
          isNull(entity('neighborhood').property('deletedAt')),
        )
        .limit(limit)
    case 'street':
      return query('street')
        .select({
          id: entity('street').property('id'),
          name: entity('street').property('name'),
          lineStrings: asGeoJson(entity('street').property('collection')),
          center: asGeoJson(entity('street').property('center')),
        })
        .where(
          some(...ids.map(id => equals(entity('street').property('id'), string().value(id)))),
          isNull(entity('street').property('deletedAt')),
        )
        .limit(limit)
    default:
      throw errors.validation(`Nível ${level} é desconhecido.`)
  }
}
