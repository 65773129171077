import { defineAction, object, enumerated, string, number, boolean } from '~/prix'
import geoUpdateOrchestratorAction from './geoUpdateOrchestrator.action.json'

const geoUpdateOrchestratorDefinition = defineAction({
  ...geoUpdateOrchestratorAction,
  input: object({
    uniqueKey: string(),
    level: enumerated({
      macroRegion: 'macroRegion',
      mesoRegion: 'mesoRegion',
      microRegion: 'microRegion',
      state: 'state',
      city: 'city',
    }).isOptional(),
    ibgeCodes: string(),
    smartMappingOptions: object({
      pointsFeatureCollection: string().isOptional(),
      customDelimitationPolygon: string().isOptional(),
      preserveClassificationsQuantile: string().isOptional(),
      maxGdfClassifications: number().isOptional(),
      minOccurrencesPerClassification: string().isOptional(),
      generalOutliersQuantile: number().isOptional(),
      classificationOutliersQuantile: number().isOptional(),
      minAreaKm2: number().isOptional(),
      simplifyEpsilon: number().isOptional(),
      withTopology: boolean().isOptional(),
      onlyDeprecated: boolean().isOptional(),
    }).isOptional(),
    openStreetMapOptions: object({
      minimumCoveragePercentage: number().isOptional(),
    }).isOptional(),
  }),
  output: object({}),
})

export default geoUpdateOrchestratorDefinition
