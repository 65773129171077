import { entity, query, string, truthy, equals, TSQuery, TSRank, number, isNull } from '~/prix'
import asGeoJson from '~/prix/functions/asGeoJson'
import concat from '~/prix/functions/concat'
import setWeight from '~/prix/functions/setWeight'
import toTsVector from '~/prix/functions/toTsVector'
import unaccent from '~/prix/functions/unaccent'

interface UserGeoState {
  stateId?: number
  stateName?: string
  stateAbbreviation?: string
}

export default function legalEntityGeoprocessingMapDemarcationSearchGeo(
  inputSearch: string,
  userGeoState: UserGeoState,
) {
  const inputWithTsWildcards = `${inputSearch.trim().replace(/\s/g, ':*&')}:*`

  return query('macroRegion')
    .select({
      id: entity('macroRegion').property('id'),
      geo: string().value('macroRegion'),
      center: asGeoJson(entity('macroRegion').property('center')),
      multiPolygon: asGeoJson(entity('macroRegion').property('boundary')),
      geoName: entity('macroRegion').property('name'),
      subtitle: string().value('Macrorregião'),
      searchRank: TSRank(
        [
          setWeight(toTsVector('simple', unaccent(entity('macroRegion').property('name'))), 'A'),
          setWeight(toTsVector('simple', string().value('Macrorregiao')), 'C'),
        ],
        unaccent(string().value(inputWithTsWildcards)),
      ),
    })
    .join({
      current: entity('macroRegion').property('id'),
      target: entity('state').property('macroRegionId'),
      join: 'inner',
    })
    .where(
      ...[
        userGeoState && userGeoState.stateId !== undefined
          ? equals(entity('state').property('id'), number().value(userGeoState.stateId))
          : null,
        TSQuery(
          [unaccent(entity('macroRegion').property('name')), string().value('Macrorregiao')],
          unaccent(string().value(inputWithTsWildcards)),
        ),
      ].filter(truthy),
    )
    .limit(userGeoState && userGeoState.stateId ? 200 : 500)
    .union(
      query('state')
        .select({
          id: entity('state').property('id'),
          geo: string().value('state'),
          center: asGeoJson(entity('state').property('center')),
          multiPolygon: asGeoJson(entity('state').property('boundary')),
          geoName: entity('state').property('name'),
          subtitle: string().value('Estado'),
          searchRank: TSRank(
            [
              setWeight(toTsVector('simple', unaccent(entity('state').property('name'))), 'A'),
              setWeight(
                toTsVector('simple', unaccent(entity('macroRegion').property('name'))),
                'C',
              ),
              setWeight(toTsVector('simple', string().value('Estado')), 'B'),
            ],
            unaccent(string().value(inputWithTsWildcards)),
          ),
        })
        .join({
          current: entity('state').property('macroRegionId'),
          target: entity('macroRegion').property('id'),
          join: 'inner',
        })
        .where(
          ...[
            userGeoState && userGeoState.stateId !== undefined
              ? equals(entity('state').property('id'), number().value(userGeoState.stateId))
              : null,
            TSQuery(
              [
                toTsVector('simple', unaccent(entity('state').property('name'))),
                toTsVector('simple', unaccent(entity('macroRegion').property('name'))),
                toTsVector('simple', string().value('Estado')),
              ],
              unaccent(string().value(inputWithTsWildcards)),
            ),
          ].filter(truthy),
        ),
      query('mesoRegion')
        .select({
          id: entity('mesoRegion').property('id'),
          geo: string().value('mesoRegion'),
          center: asGeoJson(entity('mesoRegion').property('center')),
          multiPolygon: asGeoJson(entity('mesoRegion').property('boundary')),
          geoName: entity('mesoRegion').property('name'),
          subtitle: userGeoState
            ? string().value('Mesorregião')
            : concat([string().value('Mesorregião - '), entity('state').property('abbreviation')]),
          searchRank: TSRank(
            [
              setWeight(toTsVector('simple', unaccent(entity('mesoRegion').property('name'))), 'A'),
              setWeight(
                toTsVector('simple', unaccent(entity('macroRegion').property('name'))),
                'C',
              ),
              setWeight(toTsVector('simple', string().value('Mesorregiao')), 'B'),
            ],
            unaccent(string().value(inputWithTsWildcards)),
          ),
        })
        .join({
          current: entity('mesoRegion').property('stateId'),
          target: entity('state').property('id'),
          join: 'inner',
        })
        .join({
          current: entity('state').property('macroRegionId'),
          target: entity('macroRegion').property('id'),
          join: 'inner',
        })
        .where(
          ...[
            userGeoState && userGeoState.stateId !== undefined
              ? equals(entity('state').property('id'), number().value(userGeoState.stateId))
              : null,
            TSQuery(
              [
                toTsVector('simple', unaccent(entity('mesoRegion').property('name'))),
                toTsVector('simple', unaccent(entity('macroRegion').property('name'))),
                toTsVector('simple', string().value('Mesorregiao')),
              ],
              unaccent(string().value(inputWithTsWildcards)),
            ),
          ].filter(truthy),
        ),
      query('microRegion')
        .select({
          id: entity('microRegion').property('id'),
          geo: string().value('microRegion'),
          center: asGeoJson(entity('microRegion').property('center')),
          multiPolygon: asGeoJson(entity('microRegion').property('boundary')),
          geoName: entity('microRegion').property('name'),
          subtitle: userGeoState
            ? string().value('Microrregião')
            : concat([string().value('Microrregião - '), entity('state').property('abbreviation')]),
          searchRank: TSRank(
            [
              setWeight(
                toTsVector('simple', unaccent(entity('microRegion').property('name'))),
                'A',
              ),
              setWeight(toTsVector('simple', string().value('Microrregiao')), 'C'),
            ],
            unaccent(string().value(inputWithTsWildcards)),
          ),
        })
        .join({
          current: entity('microRegion').property('mesoRegionId'),
          target: entity('mesoRegion').property('id'),
          join: 'inner',
        })
        .join({
          current: entity('mesoRegion').property('stateId'),
          target: entity('state').property('id'),
          join: 'inner',
        })
        .where(
          ...[
            userGeoState && userGeoState.stateId !== undefined
              ? equals(entity('state').property('id'), number().value(userGeoState.stateId))
              : null,
            TSQuery(
              [
                toTsVector('simple', unaccent(entity('microRegion').property('name'))),
                toTsVector('simple', string().value('Microrregiao')),
              ],
              unaccent(string().value(inputWithTsWildcards)),
            ),
          ].filter(truthy),
        ),
      query('city')
        .select({
          id: entity('city').property('id'),
          geo: string().value('city'),
          center: asGeoJson(entity('city').property('center')),
          multiPolygon: asGeoJson(entity('city').property('boundary')),
          geoName: entity('city').property('name'),
          subtitle: userGeoState
            ? string().value('Cidade')
            : concat([string().value('Cidade - '), entity('state').property('abbreviation')]),
          searchRank: TSRank(
            [
              setWeight(toTsVector('simple', unaccent(entity('city').property('name'))), 'A'),
              setWeight(toTsVector('simple', string().value('Microrregiao')), 'B'),
            ],
            unaccent(string().value(inputWithTsWildcards)),
          ),
        })
        .join({
          current: entity('city').property('microRegionId'),
          target: entity('microRegion').property('id'),
          join: 'inner',
        })
        .join({
          current: entity('microRegion').property('mesoRegionId'),
          target: entity('mesoRegion').property('id'),
          join: 'inner',
        })
        .join({
          current: entity('mesoRegion').property('stateId'),
          target: entity('state').property('id'),
          join: 'inner',
        })
        .where(
          ...[
            userGeoState && userGeoState.stateId !== undefined
              ? equals(entity('state').property('id'), number().value(userGeoState.stateId))
              : null,
            TSQuery(
              [
                toTsVector('simple', unaccent(entity('city').property('name'))),
                toTsVector('simple', unaccent(entity('microRegion').property('name'))),
                toTsVector('simple', string().value('Cidade')),
              ],
              unaccent(string().value(inputWithTsWildcards)),
            ),
          ].filter(truthy),
        ),
      query('neighborhood')
        .select({
          id: entity('neighborhood').property('id'),
          geo: string().value('neighborhood'),
          center: asGeoJson(entity('neighborhood').property('center')),
          multiPolygon: asGeoJson(entity('neighborhood').property('boundary')),
          geoName: entity('neighborhood').property('name'),
          subtitle: userGeoState
            ? concat([string().value('Bairro > '), entity('city').property('name')])
            : concat([
                string().value('Bairro > '),
                entity('city').property('name'),
                string().value(' - '),
                entity('state').property('abbreviation'),
              ]),
          searchRank: TSRank(
            [
              setWeight(
                toTsVector('simple', unaccent(entity('neighborhood').property('name'))),
                'B',
              ),
              setWeight(toTsVector('simple', unaccent(entity('city').property('name'))), 'C'),
              setWeight(toTsVector('simple', string().value('Bairro')), 'B'),
            ],
            unaccent(string().value(inputWithTsWildcards)),
          ),
        })
        .join({
          current: entity('neighborhood').property('cityId'),
          target: entity('city').property('id'),
          join: 'inner',
        })
        .join({
          current: entity('city').property('microRegionId'),
          target: entity('microRegion').property('id'),
          join: 'inner',
        })
        .join({
          current: entity('microRegion').property('mesoRegionId'),
          target: entity('mesoRegion').property('id'),
          join: 'inner',
        })
        .join({
          current: entity('mesoRegion').property('stateId'),
          target: entity('state').property('id'),
          join: 'inner',
        })
        .where(
          ...[
            userGeoState && userGeoState.stateId !== undefined
              ? equals(entity('state').property('id'), number().value(userGeoState.stateId))
              : null,
            TSQuery(
              [
                toTsVector('simple', unaccent(entity('neighborhood').property('name'))),
                toTsVector('simple', unaccent(entity('city').property('name'))),
                toTsVector('simple', string().value('Bairro')),
              ],
              unaccent(string().value(inputWithTsWildcards)),
            ),
            isNull(entity('neighborhood').property('deletedAt')),
          ].filter(truthy),
        ),
    )
}
