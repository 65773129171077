import { defineAction, enumerated, object, string } from '~/prix'
import processAddressesAction from './processAddresses.action.json'

const processAddressesDefinition = defineAction({
  ...processAddressesAction,
  input: object({
    level: enumerated({
      macroRegion: 'macroRegion',
      mesoRegion: 'mesoRegion',
      microRegion: 'microRegion',
      state: 'state',
      city: 'city',
    }).isOptional(),
    ibgeCodes: string(),
  }),
  output: object({}),
})

export default processAddressesDefinition
