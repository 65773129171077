import { defineEntity, entity, enumerated, foreign, object, string, timestamps } from '~/prix'
import { multiPolygon, point } from '~/prix/types/geoJson'

const neighborhoodEntity = defineEntity({
  key: 'neighborhood',
  title: 'Bairro',
  sources: ['olap', 'oltp'],
  schema: object({
    id: string().label('ID', 'Identificador', 'Código'),
    name: string().label('Nome', 'Bairro'),
    center: point().label('Centro'),
    boundary: multiPolygon().label('Polígono'),
    cityId: foreign(entity('city').property('id')),
    lowerQualityBoundary: multiPolygon().label('Polígono de baixa qualidade.'),
    source: enumerated({
      geometryMedian: 'Média geométrica',
      government: 'Dados das prefeituras',
      openStreetMap: 'Dados do Open Street Map',
      smartMapping: 'Dados do mapeamento inteligente.',
    }),
    ...timestamps,
  }),
})

export default neighborhoodEntity
